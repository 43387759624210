<script>
import api from '@/services/api';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import { checkLang, copyObjectWithoutID } from '@/util/helper';
import vSelect from 'vue-select';
import {
    BButton,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BRow,
    BTab,
    BTabs
} from 'bootstrap-vue';
import PageTitle from '@/components/PageTitle.vue';
import PhoneProductView from '@/views/coin/product/PhoneFrame/PhoneProductView.vue';
import PhoneListView from '@/views/coin/product/PhoneFrame/PhoneListView.vue';
import pasteImageFileAgent from '@/mixins/pasteImageFileAgent';

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor';
import { hasOwnProperty } from '@/util/object.util';
import { isArray, isNUNEZ, isObject } from '@/util/inspect.util';

export default {
    name: 'MarketProduct',
    mixins: [CRUDPageMixin, pasteImageFileAgent],
    components: {
        PhoneListView,
        PhoneProductView,
        PageTitle,
        vSelect,
        BButton,
        BCol,
        BFormCheckbox,
        BFormGroup,
        BRow,
        BFormInput,
        BTabs,
        BTab,
        quillEditor
    },

    data() {
        return {
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote' /*'code-block'*/],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        // [{ script: 'sub' }, { script: 'super' }],
                        // [{ indent: '-1' }, { indent: '+1' }],
                        // [{ direction: 'rtl' }],
                        // [{ size: ['small', false, 'large', 'huge'] }],
                        // [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        // [{ font: [] }],
                        [{ align: [] }],
                        ['link', 'image', 'video'],
                        ['clean']
                    ]
                }
            },
            items: [],
            update: {
                warehouse: []
            },
            warehouseOptions: [],
            measurementOptions: [],
            categoryOptions: [],
            dataForModal: {
                name: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                description: {
                    uz: '',
                    ru: '',
                    en: ''
                },
                images: [],
                images_id: [],
                warehouse: null,
                measurement: null,
                qty: null,
                price: null,
                category: null,
                source_link: null,
                price_discount: null,
                is_published: false
            },
            oldQty: null,
            isUpdatingPage: false,
            merchant_id: null,
            updatingItem: null,
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 10
            },
            perPageList: [10, 20, 30, 50, 100],
            localLang: localStorage.getItem('lang'),
            requestPending: false,

            fileRecords: [],
            uploadUrl: 'http://dashboard-api.1it.uz/api/uploader/image',
            uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
            fileRecordsForUpload: [],
            imageUploaded: false,
            loadingImage: false,
            prevPageName: 'coin-product',
            hasDiscount: false
        };
    },

    computed: {
        isUpdatingRole() {
            const hasParamsId = hasOwnProperty(this.$route.params, 'id');
            return !!(hasParamsId && isNUNEZ(this.$route.params.id));
        }
    },

    async mounted() {
        this.requestPending = true;
        await this.getWarehouse();
        await this.getCategories();
        this.requestPending = false;
        const params = this.$route.params;
        if (params?.id) {
            await this.fetchProduct(params.id);
            this.prevPageName = params.page;
            this.imageUploaded = true;
            this.isUpdatingPage = true;
        }
    },

    methods: {
        async fetchProduct(uuid) {
            const body = {
                method: 'coin.merchant_get_one',
                params: {
                    uuid: uuid
                }
            };
            await api.coin
                .fetchCoinProduct(body)
                .then((response) => {
                    const data = response.data.result;
                    Object.entries(data).forEach(([key, value]) => {
                        if (
                            (key === 'category' ||
                                key === 'warehouse' ||
                                key === 'description' ||
                                key === 'measurement') &&
                            value
                        ) {
                            data[key] = { ...value, name: checkLang(value.name) };
                        }
                        if (key === 'qty') {
                            this.oldQty = value;
                            data[key] = 0;
                        }
                        if (key === 'warehouse' && value) {
                            this.getMeasurements(value);
                        }
                    });
                    this.openModalToUpdate(data);
                    this.dataForModal.price_discount = data['discount_percentage'];
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        openModalToUpdate(item) {
            this.dataForModal = copyObjectWithoutID(item);
            this.update.warehouse = [Object.assign({}, this.dataForModal.warehouse)];
            this.updatingItem = item;
            Object.keys(this.updatingItem).forEach((key) => {
                if (key === 'role') {
                    this.dataForModal.role_id = this.dataForModal.role?.id;
                    delete this.dataForModal.role;
                }

                if (key === 'input_type') {
                    if (this.dataForModal.input_type?.id)
                        this.dataForModal.input_type_id = this.dataForModal.input_type.id;
                    delete this.dataForModal.input_type;
                }

                if (key === 'dependent_survey') {
                    if (this.dataForModal.dependent_survey?.uuid)
                        this.dataForModal.dependent_survey_id =
                            this.dataForModal.dependent_survey.uuid;
                    delete this.dataForModal.dependent_survey;
                }

                if (key === 'gifts') {
                    this.dataForModal.gift_id = this.dataForModal.gifts.map((gift) => {
                        return (gift = gift.id);
                    });
                    delete this.dataForModal.gifts;
                }

                if (key === 'level') {
                    this.dataForModal.level_id = this.dataForModal.level?.id;
                    delete this.dataForModal.level;
                }

                if (key === 'warehouse') {
                    if (this.warehouseOptions) {
                        const idx = this.warehouseOptions.findIndex(
                            (item) => item.id === this.dataForModal?.warehouse?.id
                        );
                        if (idx !== -1) {
                            this.dataForModal.merchant = this.warehouseOptions[idx];
                            delete this.dataForModal.warehouse_id;
                        }
                    }
                }

                if (key === 'category') {
                    if (this.categoryOptions) {
                        const idx = this.categoryOptions.findIndex(
                            (item) => item.id === this.dataForModal?.category?.id
                        );
                        if (idx !== -1) {
                            this.dataForModal.category = this.categoryOptions[idx];
                            delete this.dataForModal.category_id;
                        }
                    }
                }

                if (key === 'image') {
                    this.dataForModal.image;
                    // this.dataForModal.image_id = this.dataForModal.image?.id
                    // delete this.dataForModal.image
                }

                if (key === 'buttons' && !this.updatingItem[key]) {
                    this.dataForModal.buttons = {
                        name: {
                            uz: '',
                            ru: '',
                            en: ''
                        },
                        link: ''
                    };
                }

                if (key === 'measurement' && typeof this.dataForModal.measurement === 'object') {
                    this.dataForModal.measurement = {
                        id: this.dataForModal.measurement?.id,
                        name: this.checkLocales(this.dataForModal.measurement?.name)
                    };
                }

                if (key === 'images') {
                    for (let i = 0; i < this.dataForModal.images.length; i++) {
                        const image = this.dataForModal.images[i];
                        this.dataForModal.images[i] = {
                            id: image.id,
                            name: image.name,
                            size: image.size,
                            type: 'image/jpg',
                            url: image.path,
                            src: image.path
                        };
                    }
                }
            });
            this.isOpenModal = true;
        },
        async getWarehouse() {
            const body = {
                page: 1,
                limit: 20
            };
            await api.warehouse
                .fetchAllWarehouses(body)
                .then((response) => {
                    this.warehouseOptions = response.data.result.map((item) => {
                        return {
                            ...item,
                            name: checkLang(item.name)
                        };
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async getCategories() {
            const body = {
                page: 1,
                limit: 30
            };
            await api.productCategory
                .fetchAllCategories(body)
                .then((response) => {
                    this.categoryOptions = response.data.result.map((item) => {
                        return {
                            id: item.id,
                            name: checkLang(item.name)
                        };
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        async getMeasurements(warehouse) {
            this.merchant_id = warehouse?.merchant?.id;
            const body = {
                method: 'coin.measurement_find_all',
                params: {
                    page: 1,
                    limit: 50,
                    merchant_id: warehouse?.merchant?.id
                }
            };
            await api.coin
                .fetchCoinMeasurements(body)
                .then((response) => {
                    this.measurementOptions = response.data.result.map((item) => {
                        return {
                            id: item.id,
                            name: this.checkLocales(item.name)
                        };
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async createProduct(body, config) {
            try {
                return await api.coin.createCoinProduct(body, config);
            } catch (error) {
                this.$toast.error(
                    error.response?.data?.message || error.message || this.$t('error.smth')
                );
            }
        },

        async updateProduct(body, config) {
            try {
                return await api.coin.updateCoinProduct(body, config);
            } catch (error) {
                this.$toast.error(
                    error.response?.data?.message || error.message || this.$t('error.smth')
                );
            }
        },
        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    try {
                        if (!this.prevPageName) {
                            this.prevPageName = 'coin-product';
                        }
                        const pList = [];

                        let { warehouse } = this.dataForModal;

                        if (isObject(warehouse) && !isArray(warehouse)) {
                            warehouse = [warehouse];
                        }

                        for (let i = 0; i < warehouse.length; i++) {
                            const isForUpdate = this.update.warehouse.findIndex(
                                (m) => m.id === warehouse[i].id
                            );

                            pList.push(
                                this.generateParamsForSubmit({
                                    warehouse: warehouse[i],
                                    method: isForUpdate !== -1 ? 'update' : 'create'
                                })
                            );
                        }

                        await Promise.all(pList).then(async () => {
                            if (!this.isUpdatingRole) {
                                if (this.createOneMore) {
                                    setTimeout(() => {
                                        location.reload();
                                    }, 500);
                                    this.$toast.success(this.$t('success.added'));
                                } else {
                                    await this.$router.push({ name: this.prevPageName });
                                }
                            } else {
                                this.$toast.success(this.$t('success.updated.title'));
                                await this.$router.push({ name: this.prevPageName });
                            }
                        });
                    } catch (e) {
                        this.$toast.error(
                            e.response?.data?.message || e.message || this.$t('error.smth')
                        );
                    }
                }
            });
        },

        async generateParamsForSubmit({ warehouse, method = 'create' }) {
            let body = Object.assign(
                {},
                {
                    method: '',
                    params: {
                        warehouse_id: warehouse.id,
                        merchant_id: warehouse?.merchant?.id,
                        name: this.dataForModal.name,
                        description: this.dataForModal.description,
                        measurement_id: this.dataForModal.measurement.id,
                        source_link: this.dataForModal.source_link,
                        category_id: this.dataForModal.category.id,
                        is_published: this.dataForModal.is_published,
                        price: parseInt(this.dataForModal.price),
                        price_discount: parseInt(this.dataForModal.price_discount),
                        qty: parseInt(this.dataForModal.qty),
                        images: this.dataForModal.images.map((image, position) => ({
                            position,
                            id: image.id
                        }))
                    }
                }
            );

            if (body.params.price_discount) {
                body.params.price_discount =
                    body.params.price * (1 - body.params.price_discount / 100);
            }

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            try {
                // delete body.params.warehouse;
                // delete body.params.images_id;

                if (method === 'update') {
                    body.method = 'coin.product_update';
                    body.params.uuid = this.updatingItem.uuid;
                    return await this.updateProduct(body, config);
                } else {
                    body.method = 'coin.product_create';

                    if (this.isUpdatingRole) {
                        body.params.qty += this.oldQty;
                    }
                    return await this.createProduct(body, config);
                }
            } catch (error) {
                this.$toast.error(
                    error.response?.data?.message || error.message || this.$t('error.smth')
                );
            }
        },

        checkLocales(value) {
            if (typeof value === 'object') {
                if (this.localLang === 'uz') {
                    return value.uz;
                } else if (this.localLang === 'ru') {
                    return value.ru;
                } else {
                    return value.en;
                }
            }
            return value;
        },

        async getImageId() {
            this.loadingImage = true;
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            await this.dataForModal.images.forEach((image, index) => {
                if (image && !image.id) {
                    const formData = new FormData();
                    formData.append('file', image.file);

                    api.imageUploader
                        .imageUpload('products', formData, config)
                        .then(({ data }) => {
                            this.dataForModal.images[index] = {
                                id: data.result.id,
                                name: 'image.jpg',
                                size: 0,
                                type: 'image/jpg',
                                url: data.result.path,
                                src: data.result.path
                            };
                            this.imageUploaded = true;
                        })
                        .catch((e) => {
                            this.$toast.error(
                                e.response?.data?.message || e.message || this.$t('error.smth')
                            );
                        })
                        .finally(() => {
                            this.loadingImage = false;
                        });
                }
            });
        },

        deleteUploadedFile: function (fileRecord) {
            // Using the default uploader. You may use another uploader instead.
            this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
        },

        filesSelected: function (fileRecordsNewlySelected) {
            const validFileRecords = fileRecordsNewlySelected.filter(
                (fileRecord) => !fileRecord.error
            );
            this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
            this.imageUploaded = false;
        },

        onBeforeDelete: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                // queued file, not yet uploaded. Just remove from the arrays
                this.fileRecordsForUpload.splice(i, 1);
                const k = this.fileRecords.indexOf(fileRecord);
                if (k !== -1) this.fileRecords.splice(k, 1);
            } else {
                this.$swal({
                    title: this.$t('success.delete.text_1'),
                    text: this.$t('success.delete.text_2'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: this.$t('success.delete.button_1'),
                    cancelButtonText: this.$t('success.delete.button_2'),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
                    }
                });
            }
        },

        fileDeleted: function (fileRecord) {
            const i = this.fileRecordsForUpload.indexOf(fileRecord);
            if (i !== -1) {
                this.fileRecordsForUpload.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('navigation.coin_product') }}</template>
        </page-title>

        <div class="mb-3">
            <b-row>
                <b-col cols="8">
                    <ValidationObserver ref="createUpdateModal">
                        <!--  PRODUCT NAME  -->
                        <b-row class="product-container">
                            <div class="col-12">
                                <p class="product-container__title">
                                    {{ $t('product_page.product_name') }}
                                </p>
                            </div>

                            <!--    NAME[UZ]    -->
                            <b-col cols="4">
                                <label> {{ $t('titles.title') }} [UZ] </label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="`${$t('titles.title')} [UZ]`"
                                    rules="required"
                                >
                                    <b-form-group>
                                        <b-form-input
                                            v-model="dataForModal.name.uz"
                                            :placeholder="`${$t('enter.title')} [UZ]`"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                        />
                                    </b-form-group>
                                    <span class="validation__red">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </b-col>

                            <!--    NAME[RU]    -->
                            <b-col cols="4">
                                <label> {{ $t('titles.title') }} [RU] </label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="`${$t('titles.title')} [RU]`"
                                    rules="required"
                                >
                                    <b-form-group>
                                        <b-form-input
                                            v-model="dataForModal.name.ru"
                                            :placeholder="`${$t('enter.title')} [RU]`"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                        />
                                    </b-form-group>
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--    NAME[EN]    -->
                            <b-col cols="4">
                                <label> {{ $t('titles.title') }} [EN] </label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="`${$t('titles.title')} [EN]`"
                                    rules="required"
                                >
                                    <b-form-group>
                                        <b-form-input
                                            v-model="dataForModal.name.en"
                                            :placeholder="`${$t('enter.title')} [EN]`"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                        />
                                    </b-form-group>
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--    DESCRIPTION[UZ]    -->
                            <b-col cols="12">
                                <label> {{ $t('titles.description') }} [UZ] </label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="`${$t('titles.description')} [UZ]`"
                                    rules="required"
                                >
                                    <quill-editor
                                        v-model="dataForModal.description.uz"
                                        :options="editorOption"
                                        placeholder="asd"
                                    ></quill-editor>
                                    <span class="validation__red">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </b-col>

                            <!--    DESCRIPTION[RU]    -->
                            <b-col cols="12">
                                <label>{{ $t('titles.description') }} [RU]</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="`${$t('titles.description')} [RU]`"
                                    rules="required"
                                >
                                    <quill-editor
                                        v-model="dataForModal.description.ru"
                                        :options="editorOption"
                                        placeholder="asd"
                                    ></quill-editor>
                                    <span class="validation__red">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </b-col>

                            <!--    DESCRIPTION[EN]    -->
                            <b-col cols="12">
                                <label>{{ $t('titles.description') }} [EN]</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="`${$t('titles.description')} [EN]`"
                                    rules="required"
                                >
                                    <quill-editor
                                        v-model="dataForModal.description.en"
                                        :options="editorOption"
                                        placeholder="asd"
                                    ></quill-editor>
                                    <span class="validation__red">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </b-col>
                        </b-row>

                        <!--  PRODUCT DETAILS  -->
                        <b-row class="product-container">
                            <div class="col-12">
                                <p class="product-container__title">
                                    {{ $t('product_page.product_details') }}
                                </p>
                            </div>

                            <!--     WAREHOUSE     -->
                            <b-col cols="6">
                                <label>{{ $t('titles.warehouse') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.warehouse')"
                                    rules="required"
                                >
                                    <!--                                    :disabled="isUpdatingPage"-->
                                    <v-select
                                        v-model="dataForModal.warehouse"
                                        :multiple="true"
                                        :options="warehouseOptions"
                                        :placeholder="$t('choose.warehouse')"
                                        :searchable="false"
                                        label="name"
                                        @input="getMeasurements(dataForModal.warehouse)"
                                    />
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--     CATEGORY     -->
                            <b-col cols="6">
                                <label>{{ $t('titles.category') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.category')"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="dataForModal.category"
                                        :options="categoryOptions"
                                        :placeholder="$t('choose.category')"
                                        :searchable="false"
                                        label="name"
                                    />
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--     MEASUREMENT     -->
                            <b-col :cols="isUpdatingPage ? 4 : 6">
                                <label>{{ $t('titles.measurement') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.measurement')"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="dataForModal.measurement"
                                        :options="measurementOptions"
                                        :placeholder="$t('choose.measurement')"
                                        :searchable="false"
                                        label="name"
                                    >
                                    </v-select>
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--     QTY     -->
                            <b-col v-if="isUpdatingPage" :cols="isUpdatingPage ? 4 : 6">
                                <label>{{ $t('titles.qty') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.qty')"
                                    rules="required|numeric"
                                >
                                    <b-form-group>
                                        <b-form-input
                                            v-model="oldQty"
                                            :disabled="isUpdatingPage"
                                            :placeholder="$t('enter.qty')"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                        />
                                    </b-form-group>
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--     ADDED QTY     -->
                            <b-col :cols="isUpdatingPage ? 4 : 6">
                                <label>{{ $t('titles.adding_qty') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.adding_qty')"
                                    rules="required"
                                >
                                    <b-form-group>
                                        <b-form-input
                                            v-model="dataForModal.qty"
                                            :placeholder="$t('enter.new_qty')"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                            type="number"
                                        />
                                    </b-form-group>
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--     PRICE     -->
                            <b-col cols="12">
                                <label>{{ $t('titles.price') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="$t('titles.price')"
                                    rules="required|numeric"
                                >
                                    <b-form-group>
                                        <b-form-input
                                            v-model="dataForModal.price"
                                            :placeholder="$t('enter.price')"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                            type="number"
                                        />
                                    </b-form-group>
                                    <span class="validation__red">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </b-col>

                            <!--     PRICE DISCOUNT     -->
                            <b-col cols="12">
                                <label>{{ $t('titles.price_discount') }}</label>
                                <b-form-group>
                                    <b-form-input
                                        v-model="dataForModal.price_discount"
                                        :placeholder="$t('enter.price_discount')"
                                        size="md"
                                        type="number"
                                        :disabled="!hasDiscount"
                                    />
                                </b-form-group>
                            </b-col>

                            <!--    SOURCE    -->
                            <b-col cols="12">
                                <label> {{ $t('titles.source') }}</label>
                                <ValidationProvider
                                    v-slot="{ errors }"
                                    :name="`${$t('titles.source')}`"
                                    rules="required|regex:http"
                                >
                                    <b-form-group>
                                        <b-form-input
                                            v-model="dataForModal.source_link"
                                            :placeholder="`${$t('enter.source')}`"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                        />
                                    </b-form-group>
                                    <span class="validation__red">
                                        {{ errors[0] }}
                                    </span>
                                </ValidationProvider>
                            </b-col>

                            <!--    IS PUBLISHED?    -->
                            <b-col cols="6">
                                <label for="is_published">{{ $t('titles.is_published') }}</label>
                                <b-form-checkbox
                                    id="is_published"
                                    v-model="dataForModal.is_published"
                                />
                            </b-col>

                            <!--    HAS DISCOUNT    -->
                            <b-col cols="6">
                                <label for="hasDiscount">{{ $t('titles.unlock_discount') }}</label>
                                <b-form-checkbox id="hasDiscount" v-model="hasDiscount" />
                            </b-col>
                        </b-row>

                        <!--  PRODUCT IMAGE  -->
                        <b-row class="product-container">
                            <div class="col-12">
                                <p class="product-container__title">
                                    {{ $t('product_page.product_images') }}
                                </p>
                            </div>

                            <!--     IMAGE     -->
                            <div class="w-100 mb-2">
                                <label class="pl-1">{{ $t('titles.image') }}</label>

                                <b-col cols="12">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        :name="$t('titles.image')"
                                        rules="required"
                                    >
                                        <VueFileAgent
                                            ref="vueFileAgent"
                                            v-model="dataForModal.images"
                                            :accept="'image/*,.zip'"
                                            :deletable="true"
                                            :errorText="{
                                                type: $t('vue_file_agent.file_type_error'),
                                                size: $t('vue_file_agent.size_type_error')
                                            }"
                                            :helpText="$t('vue_file_agent.choose_image')"
                                            :maxFiles="14"
                                            :maxSize="'10MB'"
                                            :meta="true"
                                            :multiple="true"
                                            :theme="'grid'"
                                            @beforedelete="onBeforeDelete($event)"
                                            @delete="fileDeleted($event)"
                                            @select="filesSelected($event)"
                                        >
                                        </VueFileAgent>

                                        <b-button
                                            :disabled="
                                                !fileRecordsForUpload.length || imageUploaded
                                            "
                                            :variant="imageUploaded ? 'success' : 'primary'"
                                            class="float-right mt-1"
                                            @click="getImageId()"
                                        >
                                            <span v-if="!imageUploaded">
                                                {{ $t('vue_file_agent.upload_image') }}
                                            </span>
                                            <span v-else>
                                                {{ $t('vue_file_agent.image_uploaded') }}
                                            </span>
                                        </b-button>
                                        <span class="validation__red">
                                            {{ errors[0] }}
                                        </span>
                                    </ValidationProvider>
                                </b-col>
                            </div>
                        </b-row>
                    </ValidationObserver>
                </b-col>
                <b-col class="phone" cols="4">
                    <b-tabs align="left">
                        <b-tab :title="`${$t('product_page.product_tab_page')} 1`" active>
                            <div class="phone-frame">
                                <PhoneProductView
                                    :img-uploaded="imageUploaded"
                                    :old-qty="oldQty"
                                    :product="dataForModal"
                                />
                            </div>
                        </b-tab>

                        <b-tab :title="`${$t('product_page.product_tab_page')} 2`">
                            <div class="phone-frame">
                                <PhoneListView
                                    :img-uploaded="imageUploaded"
                                    :product="dataForModal"
                                />
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>

            <b-row>
                <b-col class="d-flex justify-content-end align-items-center my-2" cols="8">
                    <b-form-checkbox v-if="!updatingItem" v-model="createOneMore" class="mr-auto">
                        {{ $t('titles.add_more') }}
                    </b-form-checkbox>
                    <b-button :disabled="loadingImage" variant="success" @click="submitModal">
                        {{ modalSubmitBtnText }}
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.product-container__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0;
    color: #4b465c;
}

.product-container {
    display: flex;
    margin: 0;
    row-gap: 1rem;
    padding: 2rem 1rem;
    border: 1px solid #dbdade;
}

.phone {
    position: relative;

    &-frame {
        overflow: hidden;
        position: sticky;
        padding: 40px 1rem 1rem;
        top: 100px;
        border-radius: 1rem;
        height: 623px;
        width: 100%;
        max-width: 302px;
        background-repeat: no-repeat;
        background-image: url('/img/icons/phone-frame.svg');
        background-size: contain;

        & img {
            width: 100%;
            object-fit: contain;
        }
    }
}

::v-deep .vs__dropdown-menu {
    max-height: 250px !important;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.v-select {
    background-color: #fff;
}
</style>
